<template>
  <div class="subrouter_container">
    <div class="content">
      <div class="left">
        <div v-for="item in newsList" :key="item.title">
          <div class="title">{{ item.title }}</div>
          <div class="item" :class="current === sub.id ? 'active' : ''" v-for="sub in item.children" :key="sub.id" @click="newsClick(sub)">
            {{ sub.title }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="mb_20 breadcrumb">
          <div @click="$router.push('/')">首页</div>
          <span> /</span>
          <div @click="back">{{ ff }}</div>
          <span> /</span>
          <div @click="back">{{ ss }}</div>
          <span v-if="dd"> /</span>
          <div>{{ dd }}</div>
        </div>
        <div v-if="show">
          <div class="newsitem" v-for="item in currentData.children" :key="item.id" @click="getData(item)">
            {{ item.title }}
          </div>
        </div>
        <div v-else>
          <div v-html="info"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      currentData: {},
      newsList: [],
      ff: "",
      ss: "",
      dd: "",
      show: true,
      info: "",
    };
  },
  created() {
    this.$api("news.getNews").then((res) => {
      this.newsList = res.data;
      if (this.$route.params.id) {
        this.getItem(this.$route.params.id, this.$route.params.name);
      } else {
        this.newsClick(this.newsList[0].children[0]);
      }
    });
  },
  mounted() {
    this.$bus.$on("updateNews", this.updateNews);
  },
  methods: {
    updateNews(data) {
      this.getItem(data.id);
    },
    getItem(ID, name) {
      this.newsList.forEach((e) => {
        e.children.forEach((v) => {
          if (!name) {
            if (v.id == ID) {
              this.newsClick(v);
            }
          } else {
            v.children.forEach((o) => {
              if (o.id == ID) {
                this.newsClick(v);
                this.getData(o);
                this.dd = o.title;
              }
            });
          }
        });
      });
    },
    newsClick(item) {
      this.current = item.id;
      this.currentData = item;
      this.newsList.forEach((e) => {
        e.children.forEach((v) => {
          if (v.id == item.id) {
            this.ff = e.title;
            this.ss = v.title;
          }
        });
      });
      if (!item.children) {
        this.getData(item);
        this.show = false;
        this.dd = "";
        return;
      }
      this.show = true;
      this.dd = "";
    },
    getData(data) {
      this.show = false;
      this.dd = data.title;
      this.$api("news.getNewsDel", data).then((res) => {
        this.info = res.data.content;
      });
    },
    back() {
      this.show = true;
      this.dd = "";
    },
  },
  destroyed() {
    this.$bus.$off("updateNews", this.updateNews);
  },
};
</script>


<style lang="less" scoped>
.subrouter_container {
  padding-top: 10px;
  .content {
    display: flex;
    .left {
      border-radius: 4px;
      width: 200px;
      background-color: #fff;
      margin-right: 10px;
      .title {
        height: 34px;
        background: @themeColor;
        color: #fff;
        border-radius: 4px 4px 0px 0px;
        font-size: 16px;
        font-weight: bold;
        line-height: 34px;
        padding-left: 47px;
      }
      .item {
        margin: 10px 0 20px 47px;
        color: #666666;
        cursor: pointer;
        &:last-child {
          margin-bottom: 10px;
        }
        &:hover {
          color: @themeColor;
        }
        &.active {
          color: @themeColor;
        }
      }
    }
    .right {
      border-radius: 4px;
      flex: 1;
      background-color: #fff;
      padding: 20px;
    }
  }
  .newsitem {
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      color: @themeColor;
    }
  }
  .breadcrumb {
    display: flex;
    align-items: center;
    color: #333;
    div {
      cursor: pointer;
    }
    span {
      margin: 0 10px;
    }
  }
}
</style>
